<template>
  <the-new-header/>
  <div class="page-content">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <router-view v-slot="slotProps">
        <component :is="slotProps.Component"></component>
      </router-view>
    </main>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SearchTerms",
  data() {
    return {
      title: ''
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'sidebarIsCollapsed',
    ]),
  },
}
</script>